import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextareaAutosize,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import React, { useRef } from "react";
import backicon from "../../images/backicon.png";
import editIcon from "../../images/editIcon.png";
import maili from "../../images/maili.png";
import Navbar from "../layout/Navbar";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { ACCOUNT_SETTING_ROUTE } from "../../constants/RoutePath";
import userblue from "../../images/user blue.png";
import texprofile from "../../images/textup.jpg";
import { useState } from "react";
import {
  EDIT_ACCOUNTDETAILS_URL,
  FILE_UPLOAD_URL,
  USER_PROFILE_URL,
} from "../common/ApiUrl";
import API from "../common/API";
import { CustomMessage, authHeader, getDomain, getSideFlag } from "../common/mainfunctions";
import { useEffect } from "react";
import { useFormik } from "formik";
import CustomLoader from "../common/CustomLoader";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import uploadimage from "../../images/upload.png";
import { useDispatch, useSelector } from "react-redux";
import { MultiSelect } from "react-multi-select-component";
import { getGroupList } from "../../redux/action";
const BootstrapInputText = styled(TextareaAutosize)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F4F4F4",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const initial = {
  firstName: "",
  lastName: "",
  name: "",
  profilePath: "",
  aboutMe: "",
  role: "",
  awayFlag: "",
};
export default function AccountSettingsEdit() {
  const navigation = useNavigate();
  let domain = getDomain();
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState();
  const [proloading, setProLoading] = useState(false);
  const glist = useSelector((gl) => gl.DistributionGroupListReducer);

  const tok = authHeader();
  const cancelFun = () => {
    navigation("/account");
  };

  useEffect(() => {
    if (!tok) {
      navigation("/signin");
    } else {
      console.log("tok", `*****${tok}`);
    }
  }, [tok]);
  const editDetails = async (values) => {
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    } else {
      formData.append("file", null);
    }
    formData.append("firstName", values.name.split(" ")[0]);
    formData.append("lastName", values.name.split(" ")[1]);
    formData.append("email", values.email);
    formData.append("aboutMe", values.aboutMe);
    formData.append("profilePath", values.profilePath);
    formData.append("awayFlag", values.awayFlag);

    setLoading(true);
    const options = {
      method: "POST",
      url: EDIT_ACCOUNTDETAILS_URL,
      headers: authHeader(),
      data: formData,
      // {
      //   firstName:values.name.split(" ")[0],
      //   lastName:values.name.split(" ")[1],
      //   email:values.email,
      //   userId:'',
      //   aboutMe:values.aboutMe,
      //   file:formData,
      //      }
    };
    try {
      var apiResponse = await API(options);
      const { status, message } = apiResponse.data;
      if (status === "Success") {
        CustomMessage(message, "success", enqueueSnackbar);
      }
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        CustomMessage(message, "error", enqueueSnackbar);
      }
    } finally {
      setLoading(false);
    }
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Email address is invalid.").required("Required"),
  });
  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    setErrors,
    touched,
  } = useFormik({
    initialValues: initial,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      editDetails(values);
    },
  });
  const getUserDetails = async () => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: USER_PROFILE_URL,
    };
    try {
      const response = await API(options);
      if (response.status === 200) {
        const { status, data } = response.data;
        setDetails(data);
        const {
          firstName,
          lastName,
          profilePath,
          aboutMe,
          email,
          role,
          awayFlag,
        } = data;
        setValues({
          name: firstName.concat(" ").concat(lastName),
          profilePath: profilePath,
          aboutMe: aboutMe,
          email: email,
          profilePath: profilePath == null ? "null" : profilePath,
          role: role,
          awayFlag: awayFlag,
        });
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
      }
    }
  };

  const handleUpload = async (e) => {
    setProLoading(true);
    e.preventDefault();
    setFile(e.target.files[0]);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const options = {
      method: "POST",
      url: FILE_UPLOAD_URL,
      headers: authHeader(),
      data: formData,
    };
    try {
      var apiResponse = await API(options);
      const { status, data } = apiResponse.data;
      if (status === "Success") {
        setValues({ ...values, profilePath: data });
      }
    } catch (error) {
      if (error.response) {
        CustomMessage(error.response, "error", enqueueSnackbar);
      }
    } finally {
      setProLoading(false);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <Box
      sx={{ display: "flex" }}
      component="form"
      // onSubmit={handleSubmit}
    >
       {getSideFlag() == "true" ? (
          <></>
        ) : (
      <Navbar name="Accounts Settings" />
        )}
      {/* <Sidebar /> */}
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt:getSideFlag()=="true"?"-11px": "50px", ml: "-26px" }}>
        <Stack direction="row" spacing={2} className="tit-le">
          <IconButton
            style={{
              lineHeight: "26px",
            }}
          >
            <img src={backicon} onClick={cancelFun} />
          </IconButton>
          <Typography
            style={{
              fontWeight: 700,
              fontSize: "17px",
              lineHeight: "26px",
              marginTop: "2px",
            }}
          >
            Edit Profile
          </Typography>
        </Stack>
        <Box
          style={{
            cursor: "pointer",
            backgroundColor: "#FFFFFF",
            marginTop: "10px",
          }}
        >
          <Stack direction="row" justifyContent="center">
            <Box
              className="manageUpdate"
              style={{ margin: "15px", position: "relative" }}
            >
              <CustomLoader loading={proloading} />
              <img
                className={
                  values.profilePath != "null" ? "uploadImg" : "uploadImg1"
                }
                src={
                  values.profilePath != "null" ? values.profilePath : texprofile
                }
              />

              <Tooltip title="Click here to upload your logo" arrow>
                <label
                  style={{ marginBottom: "0", cursor: "pointer" }}
                  className="btn-bs-file btn btn-lg btn-danger admin-pro-camera"
                >
                  <img src={editIcon} />
                  <Input
                    accept=".png,.jpg,.jpeg"
                    type="file"
                    onChange={handleUpload}
                    style={{ display: "none" }}
                  />{" "}
                </label>
              </Tooltip>
            </Box>
          </Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={3}
            justifyContent="space-around"
          >
            <FormControl style={{ width: "100%" }}>
              <Input
                className="loginfields"
                style={{
                  margin: "15px",
                
                  // padding:"1px 8px !important"
                  width:'unset !important'
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <img src={userblue} />
                  </InputAdornment>
                }
                //   // required
                id="name"
                name="name"
                placeholder="Name"
                value={values.name}
                disabled
              />
            </FormControl>
            <FormControl sx={{ width: "-webkit-fill-available" }}>
              <Input
                className="loginfields1"
                style={{ margin: "15px" }}
                endAdornment={
                  <InputAdornment position="end">
                    <img src={maili} />
                  </InputAdornment>
                }
                //  required
                id="email"
                name="email"
                placeholder="Email"
                value={values.email}
                onChange={handleChange}
                error={errors.email && touched.email}
                helperText={errors.email ? errors.email : ""}
              />
              <FormHelperText error={true}>
                {errors.email && touched.email ? errors.email : ""}
              </FormHelperText>
            </FormControl>
          </Stack>
          <FormControl
            style={{
              marginLeft: "17px",
              marginRight: "15px",
              width: "-webkit-fill-available",
            }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={`Role: ${values.role}`}
              disabled
            >
              {["Role: Admin", "Role: Requester", "Role: Approver"].map(
                (rec, i) => {
                  return (
                    <MenuItem key={i} value={rec}>
                      {rec}
                    </MenuItem>
                  );
                }
              )}
            </Select>
          </FormControl>

          <Typography
            className="acc-about"
            style={{
              padding: "17px",
              paddingTop: "20px",
              paddingBottom: "0px",
            }}
          >
            ABOUT ME
          </Typography>
          <BootstrapInputText
            className="about-ara"
            style={{
              width: "-webkit-fill-available !important",
              margin: "20px",
              marginTop: "10px",
              height: "130px",
              borderRadius: "5px",
              background: "#F4F4F4",
              borderRadius: "5px",
              border: "none",
            }}
            placeholder=""
            name="aboutMe"
            id="aboutMe"
            value={values.aboutMe != "null" ? values.aboutMe : ""}
            onChange={handleChange}
          ></BootstrapInputText>
        </Box>
        <Stack
          direction="row"
          spacing={5}
          justifyContent="space-around"
          style={{ marginTop: "15px" }}
        >
          <Box
            style={{ borderRadius: "5px", textAlign: "center", width: "100%" }}
          >
            <Button
              style={{
                backgroundColor: "#F65314",
                color: "#FFFFFF",
                width: "100%",
                height: "50px",
                borderRadius: "5px",
              }}
              onClick={cancelFun}
            >
              CANCEL
            </Button>
          </Box>
          <Box
            style={{ borderRadius: "5px", textAlign: "center", width: "100%" }}
          >
            <Button
              style={{
                color: "#FFFFFF",
                backgroundColor: "#00A1F1",
                width: "100%",
                height: "50px",
                borderRadius: "5px",
              }}
              type="submit"
              onClick={handleSubmit}
            >
              {loading && <CustomLoader loading={loading ? true : false} />}
              SAVE CHANGES
            </Button>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
