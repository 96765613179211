import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import entraid from "../../../images/menid.jpg";
import { useDispatch, useSelector } from "react-redux";
import oval from "../../../images/Oval.png";
import ovalgreen from "../../../images/Oval-green.png";
import PermissionPopup from "./PermissionPopup";
import { getUserDetails } from "../../../redux/action";
import powershell from "../../../images/ps1.png";
import { PERMISSIONN_FLAG_UPDATE_URL } from "../../common/ApiUrl";
import { CustomMessage, authHeader } from "../../common/mainfunctions";
import API from "../../common/API";
import { useSnackbar } from "notistack";
export default function AzurePermission() {
  let cliendid = process.env.REACT_APP_CLIENT_ID;
  let redirecturl = process.env.REACT_APP_REDIRECT_URI;
  const userDet = useSelector((ud) => ud.UserDetailsReducer);
  const {
    msIntegrateFlag = "",
    disconnectFlag = "",
    pax8DisconnectFlag = "",
    pax8IntegrateFlag = "",
    permissionAddedFlag = "",
    powershellPermissionFlag = "",
  } = userDet || {};
  const dispatch = useDispatch();
  const [perFlag, setPerFlag] = useState(false);
  const [powershellFlag, setPowershellFlag] = useState(
    powershellPermissionFlag
  );
  const [azureFlag, setAzureFlag] = useState(permissionAddedFlag);
  const { enqueueSnackbar } = useSnackbar();

  const EnablePermission = () => {
    setPerFlag(!perFlag);
  };
  console.log(azureFlag, "azureflag");
  const handleClose = () => {
    setPerFlag(false);
    dispatch(getUserDetails());
  };
  const successcall = () => {
    console.log("called");
  };
  const flagUpdate = async () => {
    const config = {
      method: "POST",
      url: PERMISSIONN_FLAG_UPDATE_URL,
      headers: authHeader(),
      data: {
        permissionAddedFlag: null,
        powershellPermissionFlag: "1",
      },
    };
    try {
      const response = await API(config);
      const { status, data, message } = response.data;
      if (status == "Success") {
        const { powershellPermissionFlag } = data;
        CustomMessage(message, "success", enqueueSnackbar);
        setPowershellFlag(powershellPermissionFlag);
        dispatch(getUserDetails());
      }
      console.log("worked");
    } catch (error) {
      console.error(error);
    }
  };
  const handleShell = () => {
    sessionStorage.setItem("Flag", 1);
    localStorage.setItem("Flag", 1);

    let tid = localStorage.getItem("TID");

    let childWindow = window.open(
      `https://login.microsoftonline.com/${tid}/adminconsent?client_id=${cliendid}&scope=https://outlook.office365.com/.default&redirect_uri=${redirecturl}`,
      "_blank"
    );

    // Add event listener to the child window
    if (childWindow) {
      window.addEventListener("message", (event) => {
        // Handle the message received from the child window
        if (event.data.type === "flagMessage") {
          console.log("flag", event.data.type);
          console.log("Received message from child window:", event.data);
          flagUpdate();
        }
        // } else {
        //   CustomMessage("Unable to add permissions", "error", enqueueSnackbar);
        // }
      });
    }
  };
  return (
    <>
      <Grid container spacing={4} style={{ marginTop: "-20px" }}>
        <Grid item xs={12} md={6}>
          <Box
            style={{
              cursor: "pointer",
              backgroundColor: "#FFFFFF",

              height: "250px",
            }}
          >
            <Stack direction="row" spacing={2} style={{ margin: "20px" }}>
              <Box sx={{ mt: "20px" }}>
                <img
                  src={entraid}
                  style={{ height: "50px", width: "auto", marginTop: "21px" }}
                />
              </Box>
              <Box>
                <Typography
                  style={{ marginTop: "20px" }}
                  className="org-ing-head"
                >
                  Entra ID Permissions
                </Typography>
                <Box
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.15)",
                    borderRadius: "5%",
                    maxWidth: "67px",
                    padding: "0px 5px",
                    height: "22px",
                  }}
                >
                  {azureFlag == null || azureFlag == 0 ? (
                    <Stack direction="row">
                      <IconButton
                        sx={{ padding: "5px 7px", paddingLeft: "2px" }}
                      >
                        <img src={oval}></img>
                      </IconButton>
                      <Typography className="org-onoff-text">
                        Offline
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack direction="row">
                      <IconButton
                        sx={{ padding: "5px 7px", paddingLeft: "2px" }}
                      >
                        <img src={ovalgreen}></img>
                      </IconButton>
                      <Typography className="org-onoff-text">Online</Typography>
                    </Stack>
                  )}
                </Box>
                <Typography
                  style={{ marginTop: "20px" }}
                  className="org-ing-text"
                >
                  A complete identity and access management solution with
                  integrated security.
                </Typography>
              </Box>
            </Stack>
            <Box style={{ textAlign: "center", maxHeight: "80px" }}>
              {azureFlag == null || azureFlag == 0 ? (
                <Button
                  type="submit"
                  className="login-btn"
                  size="medium"
                  style={{
                    margin: "auto",
                    maxWidth: "91%",
                  }}
                  onClick={EnablePermission}
                >
                  <Typography className="org-config-btn">
                    ENABLE PERMISSIONS
                  </Typography>
                </Button>
              ) : (
                <Button
                  type="submit"
                  className="login-btn-disconnect"
                  size="medium"
                  style={{
                    // marginBottom:'20px',
                    maxWidth: "91%",
                    margin: "auto",
                  }}
                  disabled
                >
                  <Typography className="org-disconnect-btn">
                    ENABLED
                  </Typography>
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            style={{
              cursor: "pointer",
              backgroundColor: "#FFFFFF",

              height: "250px",
            }}
          >
            <Stack direction="row" spacing={2} style={{ margin: "20px" }}>
              <Box sx={{ mt: "20px" }}>
                <img
                  style={{ height: "50px", width: "auto", marginTop: "21px" }}
                  src={powershell}
                />
              </Box>
              <Box>
                <Typography
                  style={{ marginTop: "20px" }}
                  className="org-ing-head"
                >
                  Powershell Permissions
                </Typography>
                <Box
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.15)",
                    borderRadius: "5%",
                    maxWidth: "67px",
                    padding: "0px 5px",
                    height: "22px",
                  }}
                >
                  {powershellFlag == null || powershellFlag ==0? (
                    <Stack direction="row">
                      <IconButton
                        sx={{ padding: "5px 7px", paddingLeft: "2px" }}
                      >
                        <img src={oval}></img>
                      </IconButton>
                      <Typography className="org-onoff-text">
                        Offline
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack direction="row">
                      <IconButton
                        sx={{ padding: "5px 7px", paddingLeft: "2px" }}
                      >
                        <img src={ovalgreen}></img>
                      </IconButton>
                      <Typography className="org-onoff-text">Online</Typography>
                    </Stack>
                  )}
                </Box>
                <Typography
                  style={{ marginTop: "20px" }}
                  className="org-ing-text"
                >
                  A complete identity and access management solution with
                  integrated security.
                </Typography>
              </Box>
            </Stack>
            <Box style={{ textAlign: "center", maxHeight: "80px" }}>
              {powershellFlag == null ||powershellFlag==0? (
                <Button
                  type="submit"
                  className="login-btn"
                  size="medium"
                  style={{
                    margin: "auto",
                    maxWidth: "91%",
                  }}
                  onClick={handleShell}
                >
                  <Typography className="org-config-btn">
                    ENABLE PERMISSIONS
                  </Typography>
                </Button>
              ) : (
                <Button
                  // type="submit"
                  className="login-btn-disconnect"
                  size="medium"
                  style={{
                    // marginBottom:'20px',
                    maxWidth: "91%",
                    margin: "auto",
                  }}
                  disabled
                >
                  <Typography className="org-disconnect-btn">
                    ENABLED
                  </Typography>
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      {perFlag && (
        <PermissionPopup
          open={perFlag}
          handleClose={handleClose}
          azureFlag={azureFlag}
          setAzureFlag={setAzureFlag}
        />
      )}
    </>
  );
}
