import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Dashboard from '../Dashboard';
import AccountSettingIndex from '../accountSettings/AccountSettingIndex';
import OneOrganizationIndex from '../organizationSetting/OrganizationDetails/OneOrganizationIndex';
import { ACCOUNT_SETTING_ROUTE, ACTIVITY_LOGS_ROUTE, APPROVAL_REQUESTS, APPROVE_LIST_ROUTE, DASHBOARD_ROUTE, DEVICE_ROUTE, GROUP_LIST_ROUTE, MAILBOX_ROUTE, ORGANIZATION_SETTING_ROUTE, REPORTS_ROUTE, USERS_ROUTE } from '../../constants/RoutePath';
import { useNavigate } from 'react-router';
import { APPROVAR_LIST_URL, USER_PROFILE_URL } from '../common/ApiUrl';
import { authHeader, getDomain, getRole, getTentant } from '../common/mainfunctions';
import outIcon from "../../images/outicon.png";
import { IconButton, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import API from '../common/API';
import { USER_DETAILS_FLAG } from '../../redux/action/ActionType';

function TabPanel({ children, value, index, other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TeamsNavbar(){
    const [value, setValue] = React.useState(0);
    const [disableFlag,setDisableFlag]=useState(false)
const navigation=useNavigate()
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    console.log(window.location.pathname)
    const callFun=(path)=>{
      console.log(path)
    navigation(path);
      
    }
  
     const userDet = useSelector((ud) => ud.UserDetailsReducer);
  const {
    msIntegrateFlag = "",
    disconnectFlag = "",
    permissionAddedFlag = "",
    powershellPermissionFlag = "",
    intuneManagement = "",
    pax8IntegrateFlag = "",
    permissionUpdatedFlag = "",
    newUpdateFlag = "",
  } = userDet || {};
  let redirecturl = process.env.REACT_APP_REDIRECT_URI;
useEffect(()=>{
    if (window.location.pathname == "/organization" && getRole() == "Admin") {
      setValue(2);
    }else{
      navigation(DASHBOARD_ROUTE);
      setValue(0)
    }
if (
 ( (getRole() == "Admin" && msIntegrateFlag == 0) ||
  disconnectFlag == 1 ||
  permissionAddedFlag == null ||
  permissionAddedFlag == 0 ||
  powershellPermissionFlag == null ||
  powershellPermissionFlag==0)==false
) {
console.log(
  "flag1",
  disableFlag,
  msIntegrateFlag,
  disconnectFlag,
  permissionAddedFlag,
  powershellPermissionFlag
);
navigation(DASHBOARD_ROUTE)
  // setValue(2);
  // setDisableFlag(true);
}
},[])
console.log("flag",disableFlag);
console.log(
  "flag3",
  msIntegrateFlag,
  disconnectFlag,
  permissionAddedFlag,
  powershellPermissionFlag
);
const dispatch=useDispatch()
  const getUserDetailsFun = async () => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: USER_PROFILE_URL,
    };
    try {
      const response = await API(options);
      if (response.status === 200) {
        const { status, data } = response.data;
        localStorage.setItem("ORGID", data.organizationId);
        dispatch({
          type: USER_DETAILS_FLAG,
          payload: data,
        });
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
      }
    }
  };

  useEffect(() => {
    getUserDetailsFun();
  }, []);
     const clearcall = () => {
       // removeLocalStorageItemOfChildWindow()
       const popup = window.open(
         "",
         "_blank",
         `width=${window.innerWidth},height=${window.innerHeight},toolbar=no,location=no,menubar=no,titlebar=no`
       );

       // localStorage.setItem("AUTH_TOKEN", "");
       let tid = getTentant();
       let domain = getDomain();
       let d1;
       if (domain) {
         d1 = domain.split("/")[1];
       } else {
         let domain = localStorage.getItem("SubDomain");
         d1 = domain.split("/")[1];
       }
       // navigation('')
       let d2 = window.location.hostname;
       localStorage.setItem("Remove", 1);
       window.addEventListener("message", (event) => {
         console.log(event);
       });
       popup.location.href = `https://login.microsoftonline.com/${tid}/oauth2/v2.0/logout?post_logout_redirect_uri=${redirecturl}`;
     };
  return (
    <>
      <Box>
        <Stack direction="row" justifyContent="space-between">
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              maxWidth: { xs: 320, sm: 850 },
            }}
          >
            <Tabs
              className="teams-nav"
              value={value}
              onChange={handleChange}
              aria-label="scrollable auto tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label="Dashboard"
                {...a11yProps(0)}
                disabled={
                  getRole() == "Admin"
                    ? msIntegrateFlag == 0 ||
                      disconnectFlag == 1 ||
                      permissionAddedFlag == null ||
                      powershellPermissionFlag == null
                      ? true
                      : false
                    : false
                }
                onClick={() => {
                  callFun(DASHBOARD_ROUTE);
                }}
              />
              <Tab
                label="Account Settings"
                {...a11yProps(1)}
                // disabled={disableFlag}
                onClick={() => {
                  callFun(ACCOUNT_SETTING_ROUTE);
                }}
              />
              {getRole() == "Admin" && (
                <Tab
                  label="Organization Settings"
                  {...a11yProps(2)}
                  onClick={() => {
                    callFun(ORGANIZATION_SETTING_ROUTE);
                  }}
                />
              )}
              <Tab
                label="User Requests"
                {...a11yProps(3)}
                disabled={
                  getRole() == "Admin"
                    ? msIntegrateFlag == 0 ||
                      disconnectFlag == 1 ||
                      permissionAddedFlag == null ||
                      powershellPermissionFlag == null
                      ? true
                      : false
                    : false
                }
                onClick={() => {
                  callFun(APPROVE_LIST_ROUTE);
                }}
              />
              <Tab
                label="Users"
                {...a11yProps(4)}
                disabled={
                  getRole() == "Admin"
                    ? msIntegrateFlag == 0 ||
                      disconnectFlag == 1 ||
                      permissionAddedFlag == null ||
                      powershellPermissionFlag == null
                      ? true
                      : false
                    : false
                }
                onClick={() => {
                  callFun(USERS_ROUTE);
                }}
              />
              {getRole() == "Admin" && intuneManagement == 1 && (
                <Tab
                  label="Devices"
                  {...a11yProps(5)}
                  disabled={
                    getRole() == "Admin"
                      ? msIntegrateFlag == 0 ||
                        disconnectFlag == 1 ||
                        permissionAddedFlag == null ||
                        powershellPermissionFlag == null
                        ? true
                        : false
                      : false
                  }
                  onClick={() => {
                    callFun(DEVICE_ROUTE);
                  }}
                />
              )}
              <Tab
                label="Groups"
                {...a11yProps(6)}
                disabled={
                  getRole() == "Admin"
                    ? msIntegrateFlag == 0 ||
                      disconnectFlag == 1 ||
                      permissionAddedFlag == null ||
                      powershellPermissionFlag == null
                      ? true
                      : false
                    : false
                }
                onClick={() => {
                  callFun(GROUP_LIST_ROUTE);
                }}
              />
              {getRole() == "Admin" && (
                <Tab
                  label="Mailbox Maintenance"
                  {...a11yProps(7)}
                  disabled={
                    getRole() == "Admin"
                      ? msIntegrateFlag == 0 ||
                        disconnectFlag == 1 ||
                        permissionAddedFlag == null ||
                        powershellPermissionFlag == null
                        ? true
                        : false
                      : false
                  }
                  onClick={() => {
                    callFun(MAILBOX_ROUTE);
                  }}
                />
              )}
              {getRole() == "Admin" && (
                <Tab
                  label="Reports"
                  {...a11yProps(8)}
                  disabled={
                    getRole() == "Admin"
                      ? msIntegrateFlag == 0 ||
                        disconnectFlag == 1 ||
                        permissionAddedFlag == null ||
                        powershellPermissionFlag == null
                        ? true
                        : false
                      : false
                  }
                  onClick={() => {
                    callFun(REPORTS_ROUTE);
                  }}
                />
              )}
              {getRole() == "Admin" && (
                <Tab
                  label="Activity Logs"
                  {...a11yProps(9)}
                  disabled={
                    getRole() == "Admin"
                      ? msIntegrateFlag == 0 ||
                        disconnectFlag == 1 ||
                        permissionAddedFlag == null ||
                        powershellPermissionFlag == null
                        ? true
                        : false
                      : false
                  }
                  onClick={() => {
                    callFun(ACTIVITY_LOGS_ROUTE);
                  }}
                />
              )}
            </Tabs>
          </Box>
          <Box sx={{ marginTop: "auto", marginBottom: "auto" }}>
            <IconButton
              sx={{ marginRight: "20px !important" }}
              onClick={(e) => {
                e.preventDefault();
                // localStorage.clear()
                clearcall();
                // logout()
              }}
            >
              <img src={outIcon} alt="" />
            </IconButton>
          </Box>
        </Stack>
        {/* <TabPanel
          value={value}
          index={0}
          onClick={() => {
            callFun('/dashboard');
          }}
        >
        </TabPanel> */}
        <CustomTabPanel
          value={value}
          index={1}
          onClick={() => {
            callFun(ACCOUNT_SETTING_ROUTE);
          }}
        ></CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
          onClick={() => {
            callFun(ORGANIZATION_SETTING_ROUTE);
          }}
        ></CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={3}
          onClick={() => {
            callFun(APPROVE_LIST_ROUTE);
          }}
        ></CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={4}
          onClick={() => {
            callFun(USERS_ROUTE);
          }}
        ></CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={5}
          onClick={() => {
            callFun(DEVICE_ROUTE);
          }}
        ></CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={6}
          onClick={() => {
            callFun(GROUP_LIST_ROUTE);
          }}
        ></CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={7}
          onClick={() => {
            callFun(MAILBOX_ROUTE);
          }}
        ></CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={8}
          onClick={() => {
            callFun(REPORTS_ROUTE);
          }}
        ></CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={9}
          onClick={() => {
            callFun(ACTIVITY_LOGS_ROUTE);
          }}
        ></CustomTabPanel>
      </Box>
    </>
  );

} 