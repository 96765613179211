import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import Logo from "../images/logo.jpg";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import MailIcon from "../images/mail.svg";
import PassIcon from "../images/password.svg";
import { Stack } from "@mui/system";
import * as Yup from "yup";
import API from "./common/API";
import { useFormik } from "formik";
import {
  CODE_AUTH_FOR_USER_URL,
  LOGIN_URL,
  MICROSOFT_AUTHENTICATION_URL,
  SUB_DOMAIN_VALID_URL,
  USER_PROFILE_URL,
} from "./common/ApiUrl";
import {
  CustomMessage,
  CustomMessageToast,
  getDomain,
  getIntegrate,
  getRole,
} from "./common/mainfunctions";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DASHBOARD_ROUTE, SIGNUP_ROUTE } from "../constants/RoutePath";
import CustomLoader from "./common/CustomLoader";
import Facebook from "../images/facebook.svg";
import Twitter from "../images/twitter.svg";
import Linkedin from "../images/linkedin.svg";
import { useDispatch } from "react-redux";
import { MIC_AUTH_FLAG_TYPE, USER_DETAILS_FLAG } from "../redux/action/ActionType";
import { AzureAD, AuthenticationState } from "react-aad-msal";

import { authProvider } from "../authProvider";
import AuthentySuccess from "./common/AuthentySuccess";
import { useEffect } from "react";
import defaultlogo from "../images/company-23 1.png";
import notfound from "../images/notfound.svg";
import { getUserAuth, getUserDetails } from "../redux/action";
import * as microsoftTeams from "@microsoft/teams-js";
import sideLogo from "../images/logoname.png";
export default function Login(props) {
  const { token } = props;
  let domain = getDomain();
  const [info, setInfo] = useState();
  const [siloading, setSILoading] = useState(false);
  let cliendid = process.env.REACT_APP_CLIENT_ID;
  let redirecturl = process.env.REACT_APP_REDIRECT_URI;
  const { enqueueSnackbar } = useSnackbar();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [upn, setUPN] = useState();
  const [loading, setLoading] = useState(true);
  const [toggle, setToggle] = useState(true);
  const [miFlag, setMiFlag] = useState(false);
  const [domainvalid, setDomainValid] = useState(false);
  const [profile, setProfile] = useState();
  const [token1, setToken] = useState();
  const [code, setCode] = useState();
  const para = useParams();
  const [tok, setTok] = useState();
  const { id } = para;
  const [userName, setUserName] = useState();
  const queryParams = new URLSearchParams(location.search);
  const tokens = queryParams.get("tokens");
  const [sideFlag, setSideFlag] = useState(false);

  useEffect(() => {
    setToken(tokens);
  }, [tokens]);
  let spldom = window.location.origin.split("//")[1];

  let sd = spldom.split(".")[0];

  function getUrlParameterFromFragment(name) {
    const match = RegExp(`${name}=([^&]*)`).exec(location.state);
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
  }

  let idToken;
  if (location.state) {
    // Get the ID token from the URL fragment
    idToken = getUrlParameterFromFragment("id_token");
    // setTok(idToken)
  }
  const validationSchema = Yup.object({
    email: Yup.string()
      .trim()
      .email("Give valid email")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const domainValidation = async () => {
    setLoading(true);
    const config = {
      method: "POST",
      url: SUB_DOMAIN_VALID_URL,
      data: {
        subDomain: id ? id : sd,
      },
    };
    try {
      const response = await API(config);
      const { status, data, token } = response.data;
      const { profilePath } = data;
      if (status == "Success") {
        setDomainValid(true);
        setProfile(profilePath);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        // CustomMessage(data.message, "error", enqueueSnackbar);
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (id || sd) {
      domainValidation();
    }
  }, [id, sd]);

  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    setErrors,
    touched,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {},
  });

  // Listen for messages from the redirect.html page
  useEffect(() => {
    const handleMessage = (event) => {
      const { data } = event;
      if (data && data.type === "tokenMessage") {
        // Assuming that the message contains the token
        setCode(data.token.split("::")[1]);
        const receivedToken = data.token.split("::")[0];
        const base64Url = receivedToken.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const decodedData = JSON.parse(atob(base64));
        const userDisplayName = decodedData.preferred_username;
        let namesplit = decodedData.name;
        setUserName(namesplit);
        const tid = decodedData.tid;
        localStorage.setItem("TID", tid);
        setToken(receivedToken);
        setUPN(userDisplayName);
        // Respond back to the redirect.html page with a confirmation message
        event.source.postMessage({ type: "tokenReceived" }, event.origin);
      }
      if (data && data.type === "code") {
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
 useEffect(() => {
   // Check if the app is running inside Microsoft Teams
   if (window.location !== window.parent.location) {
     setSideFlag(true);
     localStorage.setItem("SIDE_FLAG", true);
     microsoftTeams.initialize();
     
   } else {
     console.log("App is running outside of Microsoft Teams");
     localStorage.setItem("SIDE_FLAG", false);
     setSideFlag(false)
   }
 }, []);
  const getMicAuthen = async () => {
    setSILoading(true);
    let us = userName.split(" ");
    const config = {
      method: "POST",
      url: MICROSOFT_AUTHENTICATION_URL,
      data: {
        emailId: upn,
        authToken: token1 || tokens,
        subDomain: window.location.hostname.split(".")[0],
        firstName: userName.split(" ")[0],
        // lastName: userName.split(" ")[1],
        lastName: us.slice(1).join(" "),
      },
    };
    try {
      const response = await API(config);
      const { status, data, token, message } = response.data;
      const { role, userId, subDomain } = data;
      if (status == "Success") {
        localStorage.setItem("AUTH_TOKEN", token);
     localStorage.setItem("SIDE_FLAG", sideFlag);
        localStorage.setItem("ROLE", role);
        localStorage.setItem("USER_ID", userId);
        let dm = "/".concat(subDomain);
        sessionStorage.setItem("DOMAIN", dm);
        localStorage.setItem("SubDomain", dm);
          const options1 = {
            method: "GET",
            headers: {Authorization: token},
            url: USER_PROFILE_URL,
          };
          try {
            const response = await API(options1);
            if (response.status === 200) {
              const { status, data } = response.data;
              localStorage.setItem("ORGID", data.organizationId);
                if (
                  role == "Admin" &&
                  (data.msIntegrateFlag == parseInt(0) ||
                    data.msIntegrateFlag == null)
                ) {
                  navigation("/organization");
                } else {
                  navigation("/dashboard");
                }
              dispatch({
                type: USER_DETAILS_FLAG,
                payload: data,
              });
            }
          } catch (error) {
            if (error.response) {
              let { data } = error.response;
            }
          }
        dispatch(getUserDetails());
        if (role != "Admin") {
          dispatch(getUserAuth(code));
        }
        CustomMessage("Login successfull", "success", enqueueSnackbar);

        const options = {
          method: "GET",
          headers: { Authorization: token },
          url: CODE_AUTH_FOR_USER_URL,
          params: {
            tokenCode: code,
          },
        };
        try {
          const resp = await API(options);
          console.log(resp);
        } catch (error) {
          console.error(error);
        }
        console.log('role',role,getIntegrate())
        setTimeout(() => {
          // if (role == "Admin" && (getIntegrate() == parseInt(0)||getIntegrate()==null)) {
          //   navigation("/organization");
          // } else {
          //   navigation("/dashboard");
          // }
        }, 1500);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        // localStorage.clear();
        CustomMessage(data.message, "error", enqueueSnackbar);
      }
    } finally {
      // navigation(ORGANIZATION_SETTING_ROUTE);
      setSILoading(false);
    }
  };
  useEffect(() => {
    if (tokens) {
      let code = tokens.split("::")[1];
      setCode(code);
      const receivedToken = tokens.split("::")[0];
      const base64Url = receivedToken.split(".")[1];
      console.log("base64", base64Url);
      let hasDash = base64Url.includes("-");
      let hasUnderscore = base64Url.includes("_");

      if (hasDash || hasUnderscore) {
        base64Url = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      }
      // const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const decodedData = JSON.parse(atob(base64Url));
      const userDisplayName = decodedData.preferred_username;
      const tid = decodedData.tid;
      let namesplit = decodedData.name;
      setUserName(namesplit);
      localStorage.setItem("TID", tid);
      setToken(receivedToken);
      setUPN(userDisplayName);
    }
    if (token1 || (tokens && domainvalid == true && siloading == false)) {
      // CustomMessage("Authenticated successfull", "success", enqueueSnackbar);

      getMicAuthen();
    }
  }, [token1, tokens]);
  const handleLoginClick = () => {
    // Open a popup window
    const popup = window.open("", "_blank", "width=500,height=600");

    // Set up a listener for postMessage
    window.addEventListener("message", (event) => {
      // Check the origin of the sender
      if (event.origin === "http://localhost:3000") {
        const { token, type } = event.data;
        popup.close();
      }
    });
    popup.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
                          response_type=id_token%20code
                          &scope=openid%20profile%20user.read%20offline_access%20ChatMessage.Send
                          &client_id=${cliendid}
                          &redirect_uri=${redirecturl}
                          &state=12345
                          &nonce=b3d7352f-1915-45a7-9460-946a06e3341e
                          &client_info=1
                          &x-client-SKU=MSAL.JS
                          &x-client-Ver=1.4.18
                          &client-request-id=c7a53cff-9437-419b-b76d-d741fb322c51
                          &response_mode=fragment`;
  };
  let fe = sessionStorage.getItem("Flag");

  // useEffect(() => {
  //   if (fe == 1) {
  //     handleLoginClick();
  //   }
  // }, [fe == 1]);
  return (
    <>
      {domainvalid == true && !loading ? (
        <Box sx={{ flexGrow: 1 }} component={"form"} onSubmit={handleSubmit}>
          <Grid container className="main" spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={11} md={6}>
              <Box className="bg-image">
                <Stack direction="row">
                  <img
                    src={sideLogo}
                    alt="logo"
                    style={{
                      // hight: "60px",
                      // width: "80px",
                      marginTop: "20px",
                      // marginLeft: "80px",
                    }}
                  />
               
                </Stack>
               

                <Stack
                  className="social-icons"
                  sx={{ pt: "20px" }}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Typography className="copyright" variant="h4">
                    HR Assist © 2024
                  </Typography>
                  <Box></Box>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              {profile ? (
                <Box
                  sx={{
                    textAlign: "center",
                    margin: "auto",
                    paddingTop: "70px",
                    width: "180px",
                    height: "180px",
                  }}
                >
                  <img
                    style={{
                      paddingTop: "40px",
                      width: "180px",
                      height: "180px",
                    }}
                    src={profile}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    textAlign: "center",
                    margin: "auto",
                    paddingTop: "70px",
                  }}
                >
                  <Box className="logo-box2">
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{
                        textAlign: "center !important",
                        margin: "auto",
                        padding: "43px",
                      }}
                    >
                      <Box style={{ width: "180px", height: "180px" }}>
                        <img src={defaultlogo} />
                      </Box>
                      <Typography>Your Logo here</Typography>
                    </Stack>
                  </Box>
                </Box>
              )}
              <Box className="form-right">
                {/* <img style={{ paddingTop: "40px" }} src={Logo} /> */}
                <Typography className="welcome-text" variant="h5">
                  Welcome to HR Assist Portal
                </Typography>
                <Typography className="login-action" variant="body2">
                  You will need to sign in to your Microsoft 365 Tenant using
                  Global
                </Typography>
                <Typography
                  className="login-action"
                  sx={{ mb: "20px" }}
                  variant="body2"
                >
                  Admin to Sign-in to your Account.
                </Typography>
                <Typography className="login-action" variant="body2">
                  You will be redirected to Microsoft 365 Tenant page to Connect
                  to your
                </Typography>
                <Typography
                  className="login-action"
                  sx={{ mb: "20px" }}
                  variant="body2"
                >
                  Global Admin Account
                </Typography>

                <div class="bsk-container">
                  <AzureAD provider={authProvider}>
                    {({
                      login,
                      logout,
                      authenticationState,
                      error,
                      accountInfo,
                    }) => {
                      const handleLoginClick = () => {
                        // Open a popup window
                        const popup = window.open(
                          "",
                          "_blank",
                          "width=500,height=600"
                        );

                        // Set up a listener for postMessage
                        window.addEventListener("message", (event) => {
                          // Check the origin of the sender
                          if (event.origin === "http://localhost:3000") {
                            const { token, type } = event.data;
                            popup.close();
                          }
                        });
                        popup.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
                          response_type=id_token%20code
                          &scope=openid%20profile%20user.read%20offline_access%20ChatMessage.Send
                          &client_id=${cliendid}
                          &redirect_uri=${redirecturl}
                          &state=12345
                          &nonce=b3d7352f-1915-45a7-9460-946a06e3341e
                          &client_info=1
                          &x-client-SKU=MSAL.JS
                          &x-client-Ver=1.4.18
                          &client-request-id=c7a53cff-9437-419b-b76d-d741fb322c51
                          &response_mode=fragment`;
                      };
                      return (
                        <>
                          <p>
                            <div
                              className="bsk-container"
                              onClick={handleLoginClick}
                            >
                              <Button className="login-btn" size="medium">
                                {siloading && (
                                  <CustomLoader loading={siloading} />
                                )}
                                <Typography className="signup-btn-text">
                                  CONTINUE TO MICROSOFT 365 TENANT PAGE
                                </Typography>
                              </Button>
                            </div>
                          </p>
                        </>
                      );
                    }}
                  </AzureAD>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : !loading ? (
        <div className="center-container">
          <div className="image-container centered-image">
            <img
              src={notfound}
              style={{ width: "100%" }}
              alt="Main Image"
              className="main-image centered-image"
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
