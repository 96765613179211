import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { alpha,styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import APIcon from "../../../images/icon api.png"
import Bitmap from "../../../images/Bitmap.png"
import { Box, FormControl, FormHelperText, Input, InputAdornment, InputBase, Stack } from '@mui/material';
import MailIcon from "../../../images/mail.svg"
import PassIcon from "../../../images/password.svg"
import { useFormik } from "formik";
import { AZURE_CONNECT_URL, VERIFY_AZURE_URL } from '../../common/ApiUrl';
import { CustomMessage, authHeader } from '../../common/mainfunctions';
import API from '../../common/API';
import { useSnackbar } from 'notistack';
import { getUserDetails } from '../../../redux/action';
import { useDispatch } from 'react-redux';
import * as Yup from "yup";
import CustomLoader from '../../common/CustomLoader';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(10),
    },
    "& .MuiInputBase-input": {
      borderRadius: "0px",
      position: "relative",
      backgroundColor: "#F8F8F8",
      //border: "2px solid #CFCFCF",
      fontSize: 18,
      width: "100%",
      padding: "18px 20px",
      color: "#343434",
      marginTop:'15px !important',
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));
  const initialValues={
    clientId:'',
    clientSecret:'',
    tenantId:'',
    email:'',
    password:'',
  }
export default function IntegrationWithAzure(props){
    // const [open, setOpen] = React.useState(false);
    const [loading,setLoading]=React.useState(false)
    const [conloading,setConLoading]=React.useState(false)
    const {enqueueSnackbar}=useSnackbar()
    const {open,handleClose}=props
const dispatch=useDispatch()
const validationSchema = Yup.object({
  clientId: Yup.string()
    .trim()
    .required("Client Id is required"),
    clientSecret: Yup.string().required("Client secret id is required"),
    tenantId: Yup.string().required("Tenant id is required"),

});
    const verifyazureFun=async(values)=>{
      const {clientId,clientSecret,tenantId}=values
      if(clientId==""||clientSecret==""||tenantId==""){
        CustomMessage('Missing required parameters. Please provide all necessary information.','error',enqueueSnackbar)
      }else{
      setLoading(true)
      const options = {
        method: 'POST',
        url: VERIFY_AZURE_URL,
        headers: authHeader(),
        data:
        {clientId:clientId,
          clientSecret:clientSecret,
          tenantId:tenantId}
      };
      try {
        var apiResponse = await API(options);
        const { status, message } = apiResponse.data;
        if (status === 'Success') {
  CustomMessage(message,"success",enqueueSnackbar)
        }
      } catch (error) {
        if (error.response) {
          const {message}=error.response.data
          CustomMessage(message,"error",enqueueSnackbar)
          }
      }finally{
        setLoading(false)
      }
    }
    }
    const connectazureFun=async()=>{
      setConLoading(true)
      const options = {
        method: 'GET',
        url: AZURE_CONNECT_URL,
        headers: authHeader(),
      }
      try {
        var apiResponse = await API(options);
        const { status, message } = apiResponse.data;
        if (status === 'Success') {
  CustomMessage(message,"success",enqueueSnackbar)
dispatch(getUserDetails())
handleClose()
        }
      } catch (error) {
        if (error.response) {
          const {message}=error.response.data
          CustomMessage(message,"error",enqueueSnackbar)
          }
      }finally{
        setConLoading(false)
      }
    }
    const {
      handleSubmit,
      handleChange,
      setValues,
      values,
      errors,
      setErrors,
      touched,
    } = useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      // validationSchema: validationSchema,
      onSubmit: (values) =>{
       verifyazureFun(values)
    
      }

    });
    const clearFun=()=>{
      setValues(initialValues)
    }
    return(
        <div>
        <Button variant="outlined" onClick={handleClose}>
          Open dialog
        </Button>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            
          </BootstrapDialogTitle>
          <DialogContent >
            <Box style={{alignItems:'center',marginLeft:"233px"}}>
            <img src={Bitmap}/>

            </Box>
            <Typography style={{fontWeight:'700',
                                fontSize:'16px',
                                alignContent:'center',
                                marginLeft:'149px'}}      >
             Azure Active Directory Integration
            </Typography>
            <form onSubmit={handleSubmit}>
            <FormControl
            sx={{ width: "100%", minWidth: "500px" }}
            variant="standard"
          >
            <BootstrapInput
              placeholder="Directory (Tenant) ID "
              name="tenantId"
              id="tenantId"
              onChange={handleChange}
              value={values.tenantId}
            />
          </FormControl>
          <FormHelperText error={true}>
            {errors.tenantId && touched.tenantId
              ? errors.tenantId
              : ""}
          </FormHelperText>
          <FormControl
            sx={{ width: "100%", minWidth: "500px" }}
            variant="standard"
          >
            <BootstrapInput
              placeholder="Application (Client) ID "
              name="clientId"
              id="clientId"
              onChange={handleChange}
              value={values.clientId}
            />
          </FormControl>
          <FormHelperText error={true}>
            {errors.clientId && touched.clientId
              ? errors.clientId
              : ""}
          </FormHelperText>
          <FormControl
            sx={{ width: "100%", minWidth: "500px" }}
            variant="standard"
          >
            <BootstrapInput
              placeholder="Client Secret"
              name="clientSecret"
              id="clientSecret"
              onChange={handleChange}
              value={values.clientSecret}
            />
          </FormControl>
          <FormHelperText error={true}>
            {errors.clientSecret && touched.clientSecret
              ? errors.clientSecret
              : ""}
          </FormHelperText>
          {/* <Typography   style={{marginTop:'10px'}}>THE FOLLWING CREDENTIALS USED FOR EMAIL FORWARDING</Typography>
          <Input
          style={{marginTop:'10px'}}
                  className="loginfields"
                  endAdornment={
                    <InputAdornment position="end">
                      <img src={MailIcon} />
                    </InputAdornment>
                  }
                  // required
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={values.email}
                  onChange={handleChange}
                  error={errors.email && touched.email}
                  helperText={errors.email && touched.email ? errors.email : ""}
                />
                <Input
                  className="loginfields"
                  sx={{ mt: 3 }}
                  // defaultValue="Password"
                  placeholder="Password"
                  id="password"
                  name="password"
                //   type={toggle?"password":"text"}
                  value={values.password}
                  onChange={handleChange}
                  error={errors.password && touched.password}
                  helperText={
                    errors.password && touched.password ? errors.password : ""
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <img src={PassIcon} 
                    //   onClick={passFun}
                      />
                    </InputAdornment>
                  }
                /> */}
                  <Stack direction="row"
            spacing={1}>
 <Button type="submit" className="login-btn1" size="medium" 
            style={{width:'368px',
            marginLeft:'10px',
        }}
            onClick={handleSubmit}
            >
              <CustomLoader loading={loading}/>
                VERIFY

              </Button>
              <Button  className="login-btn2" size="medium" 
            style={{width:'368px',
            marginLeft:'10px',
            }}
            onClick={clearFun}
            >
                CLEAR
              </Button>
            </Stack>
            </form>

            <Button type="submit" className="login-btn" size="medium" 
            style={{
            marginLeft:'10px',
        }}
            onClick={connectazureFun}
            >
              <CustomLoader loading={conloading}/>

                SAVE & CONNECT
              </Button>
              <Button type="submit" className="login-btn3" size="medium" 
            style={{
            marginLeft:'10px',
        }}
            onClick={handleClose}
            >
                CANCEL
              </Button>
          </DialogContent>
          
        </BootstrapDialog>
      </div>
    )
}