import React, { useState } from "react";
import Navbar from "../layout/Navbar";
import { Box } from "@mui/material";
import ApprovalRequestList from "./ApprovalRequestList";
import { getRole, getSideFlag } from "../common/mainfunctions";
import ApprovalRequestAll from "./ApprovalRequestAll";
export default function ApprovalRequestIndex() {
  let role = getRole();
  return (
    <>
      <Box sx={{ display: "flex" }}>
        {getSideFlag() == "true" ? <></> : <Navbar name="User Requests" />}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            mt: getSideFlag() === "true" ? "-17px" : "65px",
            ml: "-45px",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <ApprovalRequestAll />
            {/* {role=="Admin"?<ApprovalRequestAll/>: <ApprovalRequestList/>} */}
          </Box>
        </Box>
      </Box>
    </>
  );
}
