import { Box } from "@mui/material";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  redirect,
  useParams,
} from "react-router-dom";
import {
  ACCOUNT_SETTINGS_EDIT_ROUTE,
  ACCOUNT_SETTING_ROUTE,
  ACTIVITY_LOGS_ROUTE,
  APPROVAL_REQUESTS,
  DASHBOARD_ROUTE,
  GROUP_LIST_ROUTE,
  ORGANIZATION_ADD_ROUTE,
  ORGANIZATION_CREATE_WORK_FLOW,
  ORGANIZATION_DETAILS_ROUTE,
  ORGANIZATION_EDIT_ROUTE,
  ORGANIZATION_SETTING_ROUTE,
  PRIVACY_POLICY,
  REDIRECT_ROUTE,
  REPORTS_ROUTE,
  SIGNIN_ROUTE,
  SUPPORT_ROUTE,
  SYSTEM_USERS_ROUTE,
  TERMS_ROUTE,
  USERS_ROUTE,
} from "../constants/RoutePath";
import Login from "./Login";
import Sidebar from "./layout/Sidebar";
import Dashboard from "./Dashboard";
import { PrivateRoute, PublicRoute, getDomain, getSideFlag } from "./common/mainfunctions";
import ChkDashboard from "./ChkDashboard";
import AccountSettingIndex from "./accountSettings/AccountSettingIndex";
import SystemUserIndex from "./SystemUser/SystemUserIndex";
import ApprovalRequestIndex from "./approvalrequest/ApprovalRequestIndex";
import UserListIndex from "./users/UserListIndex";
import EditOrganizationDetails from "./organizationSetting/EditOrganizationDetails";
import OrganizationDetailsIndex from "./organizationSetting/OrganizationDetails/OrganizationDetailsIndex";
import GroupIndex from "./Groups/GroupIndex";
import DashboardActivityLogs from "./DashboardActivityLogs";
import AccountSettingsEdit from "./accountSettings/AccountSettingsEdit";
import RedirectFile from "./RedirectFile";
import OneOrganizationIndex from "./organizationSetting/OrganizationDetails/OneOrganizationIndex";
import ReportGraphIndex from "./reports/ReportGraphIndex";
import LogOutFile from "./LogOutFile";
import MailboxList from "./mailbox/MailboxList";
import DeviceIndex from "./Devices/DeviceIndex";
import ReportGraphIndexdev from "./reports/ReportGraphIndexdev";
import Terms from "./TermsandUse/Terms";
import Policy from "./TermsandUse/Policy";
import Support from "./TermsandUse/Support";
import TeamsNavbar from "./layout/TeamsNavbar";
export default function Layouts() {
  const para = useParams();
  const { id } = para;
  let dm = getDomain();
  let cm = sessionStorage.getItem("CURRENT");
  let sideFlag=localStorage.getItem('SIDE_FLAG')
  console.log('sideflag',sideFlag)
  return (
    <>
      {getSideFlag() == "true" || getSideFlag() == null ?  <TeamsNavbar />:<></>}

      <Box sx={{ display: "flex" }}>
        {getSideFlag()=="true"||getSideFlag()==null?<></>: <Sidebar />}

        <Box component="main" sx={{ flexGrow: 1, p: 3, pt: 2 }}>
          <Routes>
            {/* <Route path={'/signin'} element={
          
          <Login />}></Route> */}

            <Route
              exact
              path={"/signin"}
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />

            {/* <Route path={dm?`${dm}/signin`:`${cm}/signin`} element={<Login />}></Route> */}
            {/* <Route path={DASHBOARD_ROUTE} element={<Dashboard />}></Route> */}
            <Route
              exact
              path={"/dashboard"}
              // <Route exact path={`${dm}/dashboard`}
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path={"/account"}
              element={
                <PrivateRoute>
                  <AccountSettingIndex />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path={"/organization"}
              element={
                <PrivateRoute>
                  {/* <OrganizationSettingIndex/> */}
                  <OneOrganizationIndex />
                </PrivateRoute>
              }
            />
            {/* <Route exact path={SYSTEM_USERS_ROUTE} element={
      <PrivateRoute>
      <SystemUserIndex/>
      </PrivateRoute>
      }/> */}
            <Route
              exact
              path={"/approvalrequests"}
              element={
                <PrivateRoute>
                  <ApprovalRequestIndex />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path={"/users"}
              element={
                <PrivateRoute>
                  <UserListIndex />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path={"/device"}
              element={
                <PrivateRoute>
                  <DeviceIndex />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path={"/reports"}
              element={
                <PrivateRoute>
                  {/* <ReportGraphIndex /> */}
                  <ReportGraphIndexdev />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path={"/organization-edit"}
              element={
                <PrivateRoute>
                  <EditOrganizationDetails />
                </PrivateRoute>
              }
            />

            <Route
              exact
              path={"/organization-details"}
              element={
                <PrivateRoute>
                  <OrganizationDetailsIndex />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path={"/groups"}
              element={
                <PrivateRoute>
                  <GroupIndex />
                </PrivateRoute>
              }
            />
            {/* <Route
              path={"*"}
              element={<Navigate to={"pagenotfound"} />}
            ></Route> */}
            <Route
              exact
              path={"/activitylogs"}
              element={
                <PrivateRoute>
                  <DashboardActivityLogs />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path={"/account-edit"}
              element={
                <PrivateRoute>
                  <AccountSettingsEdit />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path={"/logout"}
              element={
                <PrivateRoute>
                  <LogOutFile />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path={"/mailbox"}
              element={
                <PrivateRoute>
                  <MailboxList />
                </PrivateRoute>
              }
            />
            {/* <Route exact path={'/redirect'} element={
      <PublicRoute>
      <RedirectFile/>
       </PublicRoute> 
      } /> */}
          </Routes>
        </Box>
      </Box>
    </>
  );
}
