import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Input,
  InputBase,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Logo from "../images/logo.jpg";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import MailIcon from "../images/mail.svg";
import PassIcon from "../images/password.svg";
import { Stack } from "@mui/system";
import * as Yup from "yup";
import API from "./common/API";
import { useFormik } from "formik";
import {
  CODE_AUTH_FOR_USER_URL,
  DOMAIN_BY_EMAIL_ID_URL,
  LOGIN_URL,
  SIGN_UP_URL,
} from "./common/ApiUrl";
import { CustomMessage, getDomain, getSideFlag } from "./common/mainfunctions";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_ROUTE, SIGNIN_ROUTE } from "../constants/RoutePath";
import CustomLoader from "./common/CustomLoader";
import Facebook from "../images/facebook.svg";
import Twitter from "../images/twitter.svg";
import Linkedin from "../images/linkedin.svg";
import { useDispatch } from "react-redux";
import { MIC_AUTH_FLAG_TYPE } from "../redux/action/ActionType";
import { AzureAD, AuthenticationState } from "react-aad-msal";

import { authProvider } from "../authProvider";
import AuthentySuccess from "./common/AuthentySuccess";
import { alpha, styled } from "@mui/material/styles";
import { useEffect } from "react";
import sideLogo from "../images/logoname.png"; 
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
export default function SignupdomainOption() {
  const { enqueueSnackbar } = useSnackbar();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [miFlag, setMiFlag] = useState(false);
  const [info, setInfo] = useState();
  const [option, setOption] = useState("yes");
  const [optionDomain, setOptionDomain] = useState(option);
  const [authFlag, setAuthFlag] = useState(false);
  const [token1, setToken] = useState("");
  const [code, setCode] = useState("");
  const [upn, setUPN] = useState();
  const [actualToken, setActualToken] = useState("");
  let cliendid = process.env.REACT_APP_CLIENT_ID;
  let redirecturl = process.env.REACT_APP_REDIRECT_URI;
  let defaultDomain = process.env.REACT_APP_DOMAIN;
  const handleOption = (e) => {
    setOption(e.target.value);
    setOptionDomain(e.target.value);
  };
  const handleDomain = (e) => {
    if (e.target.value == "no") {
      setValues({ ...values, domainName: "", subdomain: "" });
    } else {
      if (token1) {
        let domain = upn.split("@")[1];
        domain = domain.split(".")[0];
        setValues({
          ...values,
          domainName: domain,
          subdomain: domain.toLowerCase(),
        });
      }
    }
    setOptionDomain(e.target.value);
  };

  useEffect(() => {
    if (option == "no") {
      setValues({ ...values, domainName: "", subdomain: "" });
    } else if (option == "yes") {
      if (token1) {
        let domain = upn.split("@")[1];
        domain = domain.split(".")[0];

        setValues({
          ...values,
          domainName: domain,
          subdomain: domain.toLowerCase(),
        });
      }
    }
  }, [option, token1]);
  const infoSetFun = (e) => {
    setInfo(e);
  };
  const validationSchema = Yup.object({
    domainName: Yup.string().trim().required("Company name is required"),
    subdomain: Yup.string().required("Domain is required"),
  });
  const chkMic = () => {
    setMiFlag(true);
    dispatch({
      type: MIC_AUTH_FLAG_TYPE,
      payload: true,
    });
  };
  const continueButton = document.getElementById("continue-button");
  const signupFun = async (values) => {
    setLoading(true);
    const { emailId, firstName, lastName, domainName, subdomain } = values;
    const config = {
      method: "POST",
      url: SIGN_UP_URL,
      data: {
        firstName: firstName,
        lastName: lastName,
        emailId: emailId,
        companyName: domainName,
        subDomain: subdomain,
        authToken: token1,
      },
    };
    try {
      const response = await API(config);
      const { status, data, token } = response.data;
      // let auth
      if (status == "Success") {
        CustomMessage("Signup successfull", "success", enqueueSnackbar);
        const options = {
          method: "GET",
          headers: { Authorization: token },
          url: CODE_AUTH_FOR_USER_URL,
          params: {
            tokenCode: code,
          },
        };
        try {
          const resp = await API(options);
          console.log(resp);
        } catch (error) {
          console.error(error);
        }

        const protocol = window.location.protocol;
        console.log("pro", protocol);
        let domain = window.location.hostname.replace(
          `${values.subdomain}.`,
          ""
        );
        console.log("pro", domain);

        let signinURL;
        if (domain.includes(".")) {
          domain = domain.split(".")[1];
        }
        if (domain == "localhost") {
          signinURL = `${protocol}//${subdomain}.${domain}:3000/signin?tokens=${actualToken}`;
        } else {
          // signinURL = `${protocol}//${subdomain}.devo365toolkit.com/signin?tokens=${actualToken}`;
          // signinURL = `${protocol}//${subdomain}.office365toolkit.com/signin?tokens=${actualToken}`;
          // new dev
          signinURL = `${protocol}//${subdomain}.devhrassist.app/signin?tokens=${actualToken}`;
          // signinURL = `${protocol}//${subdomain}.hrassist.app/signin?tokens=${actualToken}`;
        }
        localStorage.setItem(
          "CURRENT",
          `${protocol}//${subdomain}.${domain}:3000`
        );
        // localStorage.setItem('newWin',1)

        // continueButton.addEventListener('click', function() {
        // window.open(signinURL, "_blank");
        // });
         if (getSideFlag() == "true") {
           window.location.href = signinURL;
         } else {
           window.open(signinURL, "_blank");
         }
        // window.open(signinURL, "_blank");
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        CustomMessage(data.message, "error", enqueueSnackbar);
      }
    } finally {
      setLoading(false);
    }
  };
  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    setErrors,
    touched,
  } = useFormik({
    initialValues: {
      domainName: "",
      subdomain: "",
      firstName: "",
      lastName: "",
      emailId: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      signupFun(values);
    },
  });
  const passFun = () => {
    setToggle(!toggle);
  };

  const chkSignup = () => {
    window.open("https://signup.live.com/signup");
  };

  const getSigninUrl = async (name, tokens) => {
    const config = {
      method: "GET",
      url: DOMAIN_BY_EMAIL_ID_URL,
      params: {
        emailId: name,
      },
    };
    try {
      const res = await API(config);
      const { data, status } = res.data;
      let subdomain;
      if (status == "Success") {
        subdomain = data.subDomain;
        const protocol = window.location.protocol;
        let domain = window.location.hostname.replace(`${subdomain}.`, "");

        let signinURL;
        if (domain.includes(".")) {
          domain = domain.split(".")[1];
        }
        console.log("domain", domain);
        if (domain == "localhost") {
          signinURL = `${protocol}//${subdomain}.${domain}:3000/signin?tokens=${tokens}`;
        } else {
          // signinURL = `${protocol}//${subdomain}.devo365toolkit.com/signin?tokens=${tokens}`;
          // signinURL = `${protocol}//${subdomain}.office365toolkit.com/signin?tokens=${tokens}`;
          signinURL = `${protocol}//${subdomain}.devhrassist.app/signin?tokens=${tokens}`;
          // signinURL = `${protocol}//${subdomain}.hrassist.app/signin?tokens=${tokens}`;
        }
        localStorage.setItem(
          "CURRENT",
          `${protocol}//${subdomain}.${domain}:3000`
        );
        if (getSideFlag() == "true"){
          window.location.href = signinURL;

        }else{
          window.open(signinURL, "_blank");
        }

      }
    } catch (error) {
      console.log(error);
      setAuthFlag(true);
    }
  };
  // Listen for messages from the redirect.html page
  useEffect(() => {
    const handleMessage = (event) => {
      const { data } = event;
      if (data && data.type == "error") {
        console.log("error requester");
        const popup = window.open("", "_blank", "width=500,height=600");

        // Set up a listener for postMessage
        window.addEventListener("message", (event) => {
          // Check the origin of the sender
          if (event.origin === "http://localhost:3000/") {
            // if (
            //   event.origin === "https://portal.devo365toolkit.com/"
            // ) {
            // Handle the message data
            const { token, type } = event.data;

            // Close the popup window if needed
            popup.close();

            // Do something with the token, e.g., log in the user
            // console.log("Received token:", token);
          }
        });

        popup.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
                          response_type=id_token%20code
                          &scope=openid%20profile%20user.read%20offline_access%20ChatMessage.Send
                          &client_id=${cliendid}
                          &redirect_uri=${redirecturl}
                          &state=12345
                          &nonce=b3d7352f-1915-45a7-9460-946a06e3341e
                          &client_info=1
                          &x-client-SKU=MSAL.JS
                          &x-client-Ver=1.4.18
                          &client-request-id=c7a53cff-9437-419b-b76d-d741fb322c51
                          &response_mode=fragment`;
      }
      if (data && data.type === "tokenMessage") {
        const atok = data.token;
        setActualToken(atok);
        // Assuming that the message contains the token
        const receivedToken = data.token.split("::")[0];
        const code = data.token.split("::")[1];
        const base64Url = receivedToken.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const decodedData = JSON.parse(atob(base64));
        const userDisplayName = decodedData.preferred_username;
        // setAuthFlag(true)
        setToken(receivedToken);
        setUPN(userDisplayName);
        setCode(code);
        let namesplit = decodedData.name.split(" ");
        setValues({
          ...values,
          firstName: namesplit[0],
          lastName: namesplit[1],
          emailId: userDisplayName,
        });
        // Respond back to the redirect.html page with a confirmation message
        event.source.postMessage({ type: "tokenReceived" }, event.origin);
        getSigninUrl(userDisplayName, receivedToken);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  return (
    <>
      <Box sx={{ flexGrow: 1 }} component={"form"}>
        <Grid container className="main" spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={11} md={6}>
            <Box className="bg-image">
              <Stack direction="row">
                <img
                  src={sideLogo}
                  alt="logo"
                  style={{
                    // hight: "60px",
                    // width: "80px",
                    marginTop: "25px",
                    marginLeft: "60px",
                  }}
                />
                {/* <Typography className="signup-title" variant="h2">
                  Assist
                </Typography> */}
              </Stack>
              {/* <Typography className="login-text" variant="body2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </Typography> */}
              <Stack
                className="social-icons"
                sx={{ pt: "20px" }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Typography className="copyright" variant="h4">
                  HR Assist © 2024
                </Typography>
                <Box>
                  {/* <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <img src={Facebook} />
                    <img src={Twitter} />
                    <img src={Linkedin} />
                  </Stack> */}
                </Box>
              </Stack>
            </Box>
          </Grid>
          {authFlag == false ? (
            <Grid item xs={12} md={6}>
              <Box className="form-right">
                <img style={{ paddingTop: "120px" }} src={""} />
                <Typography className="welcome-text" variant="h5">
                  Create an Account
                </Typography>
                <Typography className="login-action" variant="body2">
                  You will need to sign in to your Microsoft 365 Tenant using
                  Global
                </Typography>
                <Typography
                  className="login-action"
                  sx={{ mb: "20px" }}
                  variant="body2"
                >
                  Admin to Create an Account
                </Typography>
                <Typography className="login-action" variant="body2">
                  You will be redirected to Microsoft 365 Tenant page to Connect
                  to your
                </Typography>
                <Typography
                  className="login-action"
                  sx={{ mb: "20px" }}
                  variant="body2"
                >
                  Global Admin Account
                </Typography>
                <Typography
                  className="login-action"
                  sx={{ textAlign: "center" }}
                  variant="body2"
                >
                  Do you want to use your Microsoft 365 Tenant name as your HR
                  Assist
                </Typography>
                <Typography
                  className="login-action"
                  sx={{ mb: "20px", textAlign: "center" }}
                  variant="body2"
                >
                  name?
                </Typography>
                <Box
                  sx={{
                    textAlign: "center",
                    margin: "auto",
                    alignContent: "center",
                  }}
                >
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={option}
                      onChange={handleOption}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <AzureAD provider={authProvider}>
                  {({
                    login,
                    logout,
                    authenticationState,
                    error,
                    accountInfo,
                  }) => {
                    const handleLoginClick = () => {
                      // Open a popup window
                      const popup = window.open(
                        "",
                        "_blank",
                        "width=500,height=600"
                      );

                      // Set up a listener for postMessage
                      window.addEventListener("message", (event) => {
                        // Check the origin of the sender
                        if (event.origin === "http://localhost:3000/") {
                          // if (
                          //   event.origin === "https://portal.devo365toolkit.com/"
                          // ) {
                          // Handle the message data
                          const { token, type } = event.data;

                          // Close the popup window if needed
                          popup.close();

                          // Do something with the token, e.g., log in the user
                          // console.log("Received token:", token);
                        }
                      });

                      popup.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
                          response_type=id_token%20code
                          &scope=openid%20profile%20user.read%20offline_access%20ChatMessage.Send%20application.readwrite.all
                          &client_id=${cliendid}
                          &redirect_uri=${redirecturl}
                          &state=12345
                          &nonce=b3d7352f-1915-45a7-9460-946a06e3341e
                          &client_info=1
                          &x-client-SKU=MSAL.JS
                          &x-client-Ver=1.4.18
                          &client-request-id=c7a53cff-9437-419b-b76d-d741fb322c51
                          &response_mode=fragment`;
                    };
                    return (
                      <>
                        <p>
                          <div
                            className="bsk-container"
                            onClick={handleLoginClick}
                          >
                            <Button className="login-btn" size="medium">
                              {/* {siloading && ( */}
                              {/* <CustomLoader loading={siloading} /> */}
                              {/* )} */}
                              <Typography className="signup-btn-text">
                                CONTINUE TO MICROSOFT 365 TENANT PAGE
                              </Typography>
                            </Button>
                          </div>
                        </p>
                      </>
                    );
                  }}
                </AzureAD>

                {/* <AzureAD provider={authProvider}>
                  {({
                    login,
                    logout,
                    authenticationState,
                    error,
                    accountInfo,
                  }) => {
                    if (
                      authenticationState == AuthenticationState.Unauthenticated
                    ) {
                    }
                    console.error("Error", error);
                    switch (authenticationState) {
                      case AuthenticationState.Authenticated:
                        // localStorage.setItem('AUTH_TOKEN',accountInfo.jwtIdToken)
                        // navigation(ORGANIZATION_SETTING_ROUTE)
                        if (accountInfo.jwtIdToken) {
                          let domain =
                            accountInfo.account.userName.split("@")[1];
                          domain = domain.split(".")[0];
                          let namesplit = accountInfo.account.name.split(" ");
                          setValues({
                            ...values,
                            firstName: namesplit[0],
                            lastName: namesplit[1],
                            emailId: accountInfo.account.userName,
                          });
                          setInfo(accountInfo);

                          setAuthFlag(true);
                        }
                        return (
                          <>
                            {accountInfo.jwtIdToken && (
                              // <AuthentySuccess tok={accountInfo.jwtIdToken} username={accountInfo.account.userName}/>}
                              <></>
                            )}
                            <p>
                              <div class="bsk-container" onClick={login}>
                                <Button
                                  className="login-btn"
                                  size="medium"
                                  // onClick={chkMic}
                                >
                                  <Typography className="signup-btn-text">
                                    CONTINUE TO MICROSOFT 365 TENANT PAGE
                                  </Typography>
                                </Button>
                              </div>
                              <p>
                                Have an existing account?
                                <a
                                  href="javascript:void(0)"
                                  onClick={login}
                                  id="continue-button"
                                >
                                  Click here to Sign In
                                </a>
                              </p>
                            </p>
                          </>
                        );
                      case AuthenticationState.Unauthenticated:
                        // login()
                        infoSetFun(accountInfo);

                        return (
                          <>
                            <div class="bsk-container" onClick={login}>
                              <Button
                                className="login-btn"
                                size="medium"
                                // onClick={chkMic}
                              >
                                <Typography className="signup-btn-text">
                                  CONTINUE TO MICROSOFT 365 TENANT PAGE
                                </Typography>
                              </Button>
                            </div>
                            <p>
                              Have an existing account?
                              <a href="javascript:void(0)" onClick={login}>
                                Click here to Sign In
                              </a>
                            </p>
                          </>
                        );
                      case AuthenticationState.InProgress:
                        return (
                          <>
                            <div class="bsk-container" onClick={login}>
                              <Button
                                className="login-btn"
                                size="medium"
                                // onClick={chkMic}
                                onClick={login}
                              >
                                <Typography className="signup-btn-text">
                                  CONTINUE TO MICROSOFT 365 TENANT PAGE
                                </Typography>
                              </Button>
                            </div>
                            <p>
                              Have an existing account?
                              <a href="javascript:void(0)" onClick={login}>
                                Click here to Sign In
                              </a>
                            </p>
                          </>
                        );
                    }
                  }}
                </AzureAD> */}
                {/* <div class="bsk-container" onClick={chkMic}>
    <button class="bsk-btn bsk-btn-default">
      <object type="image/svg+xml" style={{marginRight:'10px'}} data="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg" class="x-icon"></object> 
      Sign in with Microsoft</button>
  </div> */}
                {/* <Typography className="account-text" variant="h4">
                Already have an account?{" "}
                <span className="create-account" onClick={loginFun}>Login</span>
              </Typography> */}
              </Box>
            </Grid>
          ) : (
            <Grid item xs={12} md={6}>
              <Box className="form-right">
                <img style={{ paddingTop: "40px" }} src={""} />
                <Typography
                  className="welcome-text"
                  variant="h5"
                  sx={{ paddingTop: "100px" }}
                >
                  Welcome to HR Assist Portal
                </Typography>
                <Typography className="login-action" variant="body2">
                  Would you like to match our subdomain to the existing
                </Typography>
                <Typography
                  className="login-action"
                  sx={{ mb: "20px" }}
                  variant="body2"
                >
                  onmicrosoft.com subdomain
                </Typography>
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                  margin: "auto",
                  alignContent: "center",
                }}
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={optionDomain}
                    onChange={handleDomain}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ textAlign: "center", margin: "30px" }}>
                <div class="bsk-container">
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <BootstrapInput
                      name="domainName"
                      id="domainName"
                      value={values.domainName}
                      disabled={optionDomain == "yes" ? true : false}
                      onChange={handleChange}
                      placeholder="Company Name"
                    />
                    <FormHelperText error={true}>
                      {errors.domainName && touched.domainName
                        ? errors.domainName
                        : ""}
                    </FormHelperText>
                  </FormControl>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ paddingTop: "10px" }}
                  >
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <BootstrapInput
                        name="subdomain"
                        id="subdomain"
                        value={values.subdomain}
                        disabled={optionDomain == "yes" ? true : false}
                        onChange={handleChange}
                        placeholder="Sub domain"
                      />
                      <FormHelperText error={true}>
                        {errors.subdomain && touched.subdomain
                          ? errors.subdomain
                          : ""}
                      </FormHelperText>
                    </FormControl>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography sx={{ paddingTop: "20px" }}>
                        {defaultDomain}
                      </Typography>
                    </Box>
                  </Stack>
                  <Button
                    className="login-btn"
                    size="medium"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    {loading && <CustomLoader loading={loading} />}
                    <Typography className="signup-btn-text">SIGN-UP</Typography>
                  </Button>
                </div>
              </Box>
            </Grid>
          )}
        </Grid>
        {/* {miFlag==true&&<AuthWorkPage/>} */}
        {/* {miFlag&&
        <AzureAD provider={authProvider}>
        <span>Only authenticated users can see me.</span>
      </AzureAD>
      } */}
      </Box>
    </>
  );
}
